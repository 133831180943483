import React, { useState } from 'react';
import { Box, VStack, Button, Image, Heading, Text, Textarea } from '@chakra-ui/react';

function App() {
  const [showSentence, setShowSentence] = useState(false);
  const [randomSentence, setRandomSentence] = useState('');

  const generateRandomSentence = () => {
    const subjects = ["Kocour", "Auto", "Mrak", "Astronaut", "Majkl", "Aneta", "Táďa", "Luxík"];
    const verbs = ["skočil", "jede", "rostl", "plaval", "stojí", "tančí", "zpívá", "programuje", "koupe", "spí", "jí", "pil", "křičela"];
    const adjectives = ["rychlý", "vysoký", "zelený", "temný", "starý", "veselý", "rozmrzelý", "barevný", "hlučná", "nasraná", "sprostá", "unavená"];
    const objects = ["na střechu", "po silnici", "v lese", "po obloze", "na rohu", "pod mořem", "do vesmíru", "na Mars"];
    const adverbs = ["elegantně", "hlučně", "tichounce", "divoce", "s radostí", "ve spěchu", "bezstarostně", "zvědavě"];
    const conjunctions = ["a pak", "když najednou", "ale", "dokud", "protože", "i přesto, že", "přestože", "zatímco"];

    // Select two distinct indices for subjects
    const randomSubjectIndex1 = Math.floor(Math.random() * subjects.length);
    let randomSubjectIndex2 = Math.floor(Math.random() * subjects.length);
    while (randomSubjectIndex2 === randomSubjectIndex1) {
      randomSubjectIndex2 = Math.floor(Math.random() * subjects.length);
    }

    const randomSubject1 = subjects[randomSubjectIndex1];
    const randomSubject2 = subjects[randomSubjectIndex2];
    const randomVerb = verbs[Math.floor(Math.random() * verbs.length)];
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomObject = objects[Math.floor(Math.random() * objects.length)];
    const randomAdverb = adverbs[Math.floor(Math.random() * adverbs.length)];
    const randomConjunction = conjunctions[Math.floor(Math.random() * conjunctions.length)];

    const sentence = `${randomSubject1} ${randomVerb} ${randomAdjective} ${randomObject} ${randomAdverb}, ${randomConjunction} ${randomSubject2} ${randomVerb} opět.`;
    setRandomSentence(sentence);
    setShowSentence(true);
  };

  return (
    <Box textAlign="center" fontSize="xl">
      <Heading mb={4}>Vítejte na generátoru náhodných slov. Veselý den!</Heading>

      <Image src="https://res.cloudinary.com/ddw1u49ec/image/upload/v1706652748/aaneta_upscayl_4x_realesrgan-x4plus-anime_thqovj.jpg" borderRadius="lg" boxSize="200px" mx="auto" mb={4} alt="Aneta" />

      <Text fontSize="lg" mb={4}>Jaképak slovo Aneta zase vyplodí?</Text>

      <VStack spacing={4}>
        <Button colorScheme="blue" onClick={generateRandomSentence}>generuj slova</Button>
        {showSentence && (
          <Textarea 
            value={randomSentence} 
            isReadOnly 
            w={['80vw', '70vw', '60vw', '50vw']} // Responsive width
            h={['100px', '80px']} // Responsive height
            resize="none" // Prevent resizing
            overflowY="auto" // Add scroll for overflow text
          />
        )}
      </VStack>
    </Box>
  );
}

export default App;